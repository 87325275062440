<template>
  <v-dialog
    v-model="showWelcome"
    @keydown.esc="close()"
    @click:outside="close()"
    width="auto"
  >
    <v-card max-width="670" class="text-center pt-6 pb-4 px-4">
      <v-card-title
        class="justify-center display-1 pb-4 primary--text font-weight-bold"
      >
        {{ $ml.get("title") }}
      </v-card-title>
      <v-card-subtitle v-html="$ml.get('subtitle')" class="body-1 mt-0 mb-0" />

      <v-card-text
        class="elevation-0 mb-2"
        color="transparent"
        v-for="item in cards"
        :key="item.order"
      >
        <v-row class="ma-0 px-4 py-0">
          <v-col cols="12" sm="2">
            <logo
              v-if="item.logo"
              product="conecta_control"
              class="mx-auto my-auto"
              :height="60"
            />
            <v-icon
              v-else
              v-text="`mdi-${item.icon}`"
              :color="'primary'"
              size="68"
              class="mx-auto my-auto"
            />
          </v-col>
          <v-col cols="12" sm="10" class="text-left">
            <div class="primary--text text-h5 font-weight-medium">
              {{ $ml.get(item.title) }}
            </div>
            <v-card-subtitle class="pa-0 ma-0">
              {{ $ml.get(item.description) }}
            </v-card-subtitle>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="d-flex flex-column">
        <v-btn
          x-large
          block
          dark
          color="accent"
          elevation="0"
          class="text-body-1 text-none font-weight-medium"
          @click="setTour()"
        >
          {{ $ml.get("startTutorial") }}
        </v-btn>
        <v-btn
          large
          block
          :color="$vuetify.theme.dark ? 'white' : 'secondary'"
          text
          elevation="0"
          class="body-2 text-none text-decoration-underline font-weight-medium mt-4"
          @click="close()"
        >
          {{ $ml.get("buttonSkip") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "Welcome",

  data() {
    return {
      cards: [
        {
          title: "step1title",
          logo: true,
          description: "step1description",
          order: 0,
        },
        {
          title: "step2title",
          icon: "briefcase-clock",
          description: "step2description",
          order: 1,
        },
        {
          title: "step3title",
          icon: "power",
          description: "step3description",
          order: 2,
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["showWelcome"]),
  },
  methods: {
    setTour() {
      this.$emit("close", { tour: true, message: false });
    },
    close() {
      this.$emit("close", { message: false });
    },
  },
};
</script>
