<template>
  <v-container fluid class="ma-0 pa-0">
    <welcome @close="closeWelcome" />
    <v-tour
      name="onBoarding"
      :steps="steps"
      :options="tourOptions"
      :callbacks="myCallbacks"
    >
    </v-tour>

    <v-row
      :class="`align-center ma-0 px-1`"
      align-content="center"
      justify-lg="start"
    >
      <v-expand-x-transition v-for="(card, i) in allCards" :key="i">
        <v-col xs="1" md="3" lg="3" xl="2">
          <ItemCard
            :title="card.title"
            :description="card.description"
            :icon="card.icon"
            :logo="card.logo"
            :type="card.type"
            :action="card.action"
            :id="getCardId(card.title)"
          />
        </v-col>
      </v-expand-x-transition>
    </v-row>
  </v-container>
</template>

<script>
import ItemCard from "@/components/home/ItemCard";
import Welcome from "@/components/home/Welcome.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Home",
  components: { ItemCard, Welcome },
  data() {
    return {
      myCallbacks: {
        onSkip: this.endOfTour,
        onFinish: this.endOfTour,
        onStop: this.endOfTour,
      },
      helpImage: require("@/assets/images/Ajuda.gif"),
      show: true,
    };
  },
  computed: {
    ...mapGetters([
      "isSsoActive",
      "tour",
      "loading",
      "hasScheduleActivationInfo",
    ]),

    showActivationPage() {
      return this.loading
        ? false
        : !this.isSsoActive && !this.hasScheduleActivationInfo;
    },

    allCards() {
      return [
        {
          icon: "mdi-power",
          type: "to",
          action: "/activation-steps",
          title: "active_control",
          description: "activate_control_tour",
          show: this.showActivationPage,
        },
        {
          icon: "mdi-account-multiple",
          type: "to",
          action: "/users",
          title: "users",
          description: "users_description",
          show: true,
        },
        {
          icon: "mdi-store",
          title: "company_profile",
          type: "href",
          action: `${process.env.VUE_APP_SUITE_BASE_URL}/company`,
          description: "company_profile_label",
          show: true,
        },
        {
          icon: "mdi-account-key",
          type: "to",
          action: "/admin",
          title: "user_permissions",
          description: "admin_permissions_description",
          show: true,
        },
        {
          icon: "mdi-briefcase-clock",
          type: "to",
          action: "/groups",
          title: "groups",
          description: "groups_description",
          show: true,
        },
        {
          icon: "mdi-lock",
          type: "to",
          action: "/password-settings",
          title: "password_settings",
          description: "password_settings_description",
          show: true,
        },
        {
          logo: "conecta_suite",
          type: "href",
          action: `${process.env.VUE_APP_SUITE_BASE_URL}`,
          title: "conecta_suite",
          description: "conecta_suite_description",
          show: true,
        },
        {
          icon: "mdi-poll",
          type: "to",
          action: "/reports",
          title: "reports",
          description: "reports_description",
          show: true,
        },
        {
          icon: "mdi-shape-square-rounded-plus",
          type: "to",
          action: "/integrations",
          title: "integrations",
          description: "integrations_description",
          show: true,
        },
        {
          icon: "mdi-tools",
          type: "to",
          action: "/customize",
          title: "customize_login",
          description: "customize_description",
          show: true,
        },
        {
          icon: "mdi-alert",
          type: "to",
          action: "/",
          title: "alerts",
          description: "alerts_description",
          show: false,
        },
      ].filter((item) => item.show);
    },

    steps() {
      return [
        {
          target: "#v-step-0",
          header: {
            title: this.$ml.get("user_tour"),
          },
          content: this.$ml.get("user_tour_description"),
        },
        {
          target: "#v-step-1",
          header: {
            title: this.$ml.get("company_profile_tour"),
          },
          content: this.$ml.get("company_profile_tour_description"),
        },
        {
          target: "#v-step-2",
          header: {
            title: this.$ml.get("permissions_tour"),
          },
          content: this.$ml.get("permissions_tour_description"),
        },
        {
          target: "#v-step-3",
          header: {
            title: this.$ml.get("group_tour"),
          },
          content: this.$ml.get("group_tour_description"),
        },
        {
          target: "#v-step-4",
          header: {
            title: this.$ml.get("password_tour"),
          },
          content: this.$ml.get("password_tour_decription"),
        },
        {
          target: "#v-step-5",
          header: {
            title: this.$ml.get("integrations_tour"),
          },
          content: this.$ml.get("integrations_tour_description"),
          params: {
            placement: `${this.isMobile ? "bottom" : "left"}`,
          },
        },
        {
          target: "#v-step-6",
          header: {
            title: this.$ml.get("suite_tour"),
          },
          content: this.$ml.get("suite_tour_description"),
        },
        {
          target: "#v-step-7",
          header: {
            title: this.$ml.get("config_tour"),
          },
          content: this.$ml.get("config_tour_description"),
        },
        {
          target: "#v-step-8",
          header: {
            title: this.$ml.get("activate_control_tour"),
          },
          content: this.$ml.get("activate_control_tour_description"),
          show: !this.showActivationPage,
        },
      ].filter((item) => !item.show);
    },

    tourOptions() {
      return {
        startTimeout: 5,
        highlight: true,
        labels: {
          buttonSkip: this.$ml.get("buttonSkip"),
          buttonPrevious: this.$ml.get("buttonPrevious"),
          buttonNext: this.$ml.get("buttonNext"),
          buttonStop: this.$ml.get("buttonStop"),
        },
      };
    },

    isMobile() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  methods: {
    ...mapActions(["updateWelcome"]),

    getCardId(cardTitle) {
      if (cardTitle === "users") {
        return "v-step-0";
      } else if (cardTitle === "company_profile") {
        return "v-step-1";
      } else if (cardTitle === "user_permissions") {
        return "v-step-2";
      } else if (cardTitle === "groups") {
        return "v-step-3";
      } else if (cardTitle === "password_settings") {
        return "v-step-4";
      } else if (cardTitle === "integrations") {
        return "v-step-5";
      } else if (cardTitle === "conecta_suite") {
        return "v-step-6";
      } else if (cardTitle === "active_control" && this.showActivationPage) {
        return "v-step-8";
      }
    },

    endOfTour() {
      this.closeWelcome({ tour: false, message: false });
    },

    beforeDestroy() {
      this.endOfTour();
    },

    async closeWelcome(payload) {
      if (payload.tour) {
        await this.updateWelcome(payload);
        this.$tours["onBoarding"].start();
      } else {
        this.updateWelcome(payload);
      }
    },
  },
};
</script>
